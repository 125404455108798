import React from 'react';

const AboutMe = () => {
    return (
        <section id="about-me" className="block spacer p-top-xl p-bottom-lg">
            <div className="wrapper">
                <div className="row gutter-width-md with-pb-lg justify-content-center">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <div className="about-me-img" data-aos="zoom-in-right">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="assets/img/bg/about-680x820.jpg" alt="About" />
                                </div>
                            </div>

                            <div className="img-2" data-aos="fade-up">
                                <img src="assets/img/logo/logo.png" alt="signed" />
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <div className="d-flex flex-column justify-content-center h-100">
                            <div className="title" data-aos="fade-up">
                                <h2 className="pb-0">About me</h2>
                            </div>

                            <div className="sub-title spacer p-top-lg" data-aos="fade-up">
                                <h3>A passionate creative with relentless curiosity</h3>
                            </div>

                            <div className="description spacer p-top-lg" data-aos="fade-up">
                                <p>I’m a superb coder with an artistic mind. Unlike many, I never chose between being an artist or an engineer. I always wanted to do both, so I did. Naturally, after some time, my creativity began seeping into my code and my logic into my creativity, and I found myself hovering towards projects that aligned with those dualities. </p>
                                <p>I'm determined to change the World. My vision is to use technology and art as tools to challenge the status quo and empower and inspire communities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutMe;
