import React, { Component } from 'react';
import axios from 'axios';
const API_KEY = "a783de3ab8b4876dbfa0809d0f31b479d5f96b06132da2e38ece25cd16a4de58";
const INBOX_KEY = 'f4dcb619-fda8-46a2-9bea-3bd74871cfd6'

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                senderId: '',
                to: 'emailmelithedev@gmail.com',
                email: '',
                subject: '',
                message: '',
                name: ''
            },
            successMessage: "Your message was sent successfully. I will be getting back to you as soon as possible",
            warningMessage: 'Fill up the form, please!',
            errorMessage: 'Whoops! Something went wrong while sending your message. Please email me at emailmelithedev@gmail.com instead',
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000
        };
    };

    createInbox = async () => {
        // call MailSlurp createInbox endpoint
        return await axios
            .post(`https://api.mailslurp.com/createInbox?apiKey=${API_KEY}`)
            .then((res) => res.data);
    }

    submitForm = async e => {
        e.preventDefault();

        //var inbox1 = this.createInbox();

        if (document.querySelector('#alert')) {
            document.querySelector('#alert').remove();
        }

        this.setState({
            isSubmitting: true,
            body: this.state.values.message,
        });


        axios.post('https://api.mailslurp.com/sendEmail?apiKey=' + API_KEY, {
            senderId: INBOX_KEY,
            to: this.state.values.to,
            subject: 'Contact form message from ' + this.state.values.name,
            body: this.state.values.message
        })
            .then(response => {
                this.setState({ responseMessage: this.state.successMessage });
                this.callAlert(this.state.responseMessage, response.status)
            })
            .catch(error => {
                const response = error.response;
                console.log(response);
                this.setState({ responseMessage: this.state.errorMessage });
                this.callAlert(this.state.responseMessage, response.status)
                this.callAlert(this.state.errorMessage, 'error')
            });
    };

    removeAlert = () => {
        clearTimeout(this.state.alertTimeout);
        this.setState({
            alertTimeout: setTimeout(function () {
                var element = document.querySelector('#alert');
                element.classList.remove('fadeIn');
                element.classList.add('fadeOut');
                setTimeout(function () {
                    element.remove()
                }, 2000)
            }, this.state.delay
            )
        });
    };

    callAlert = (message, type) => {
        if (!document.querySelector('#alert')) {
            if (type === 'success') {
                this.setState({ alertClass: 'success' })
            }

            if (type === 'error') {
                this.setState({ alertClass: 'danger' })
            }

            if (type === 'warning') {
                this.setState({ alertClass: 'warning' })
            }

            var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' + this.state.alertClass + '">' + message + '</div>';

            var element = document.querySelector('#cf-1');

            element.insertAdjacentHTML('beforeend', alert);

            //this.removeAlert();
        }
    };

    handleInputChange = e =>
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        });

    render() {
        return (
            <form onSubmit={this.submitForm} method="post" action="form.php" id="cf-1" className="contact-form" data-aos="fade-up">
                <div className="form-group form-group-sm" data-aos="fade-up">
                    <label htmlFor="cf-1-name" className="form-label-lg before">Name</label>
                    <input
                        name="name"
                        type="text"
                        id="cf-1-name"
                        placeholder="Enter your name"
                        required="required"
                        className="form-control form-control-lg"
                        value={this.state.values.name}
                        onChange={this.handleInputChange}
                    />
                </div>

                <div className="form-group form-group-sm" data-aos="fade-up">
                    <label htmlFor="cf-1-email" className="form-label-lg before">Email</label>
                    <input
                        name="email"
                        type="email"
                        id="cf-1-email"
                        placeholder="Enter your email"
                        required="required"
                        className="form-control form-control-lg"
                        value={this.state.values.email}
                        onChange={this.handleInputChange}
                    />
                </div>

                <div className="form-group form-group-sm" data-aos="fade-up">
                    <label htmlFor="cf-1-message" className="form-label-lg before">Message</label>
                    <textarea
                        name="message"
                        id="cf-1-message"
                        placeholder="Enter your message"
                        required="required"
                        className="form-control form-control-lg"
                        value={this.state.values.message}
                        onChange={this.handleInputChange}
                    ></textarea>
                </div>

                <div className="form-group form-group-sm mb-0" data-aos="fade-up">
                    <button type="submit" className="btn btn-primary">Send Message</button>
                </div>
            </form>
        );
    };
};

export default ContactForm;
