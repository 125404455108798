import React from 'react';

const PageTitle = (props) => {
    const title = props.title;
    const category = props.category;

    return (
        <div id="page-title" className="page-title-height d-flex">
            <div className="wrapper">
                <div className="d-flex justify-content-center">
                    <div className="title text-center" data-aos="fade-down">
                        <h1>{title}</h1>
                        <h5>{category}</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageTitle;
