import React from 'react';

const Footer = (props) => {
    const homePageText = props.homePageText;
    const homePageUrl = props.homePageUrl;

    return (
        <footer id="footer" className="site-footer">
            <div className="wrapper">
                <div className="footer">
                    <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-between">
                        <div className="align-self-center">
                            <div className="copyright">
                                <p>© {new Date().getFullYear()} Meli Jiménez</p>
                            </div>
                        </div>
                        <div className="align-self-center home-link">
                            <p><a href={homePageUrl}>{homePageText}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
